import React from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { AppState } from '@ttstr/reducers';
import { useShallowEqualSelector } from '@ttstr/utils';
import Container from 'reactstrap/lib/Container';

import { CheckoutWrapper } from '@ttstr/components/Checkout';

declare global {
  interface Window {
    gtag: any | undefined;
    fbq: any | undefined;
    ttq: any | undefined;
  }
}

const Checkout: React.FC = () => {
  const { t } = useTranslation();
  const [price, contents] = useShallowEqualSelector(mapStateToProps);
  const history = useHistory();
  const params = { value: price, currency: 'EUR', contents };

  React.useEffect(() => {
    if (window.fbq) {
      // console.log("FB once?! InitiateCheckout")
      window.fbq('track', 'InitiateCheckout');
    }

    if (window.ttq) {
      // console.log("TT once?! InitiateCheckout")
      window.ttq.track('InitiateCheckout', params);
    }
  }, []);

  React.useEffect(() => {
    if (history.location.pathname === '/checkout/confirm') {
      const button = document.getElementById('confirm-purchase-button');
      if (button) {
        const func1 = function () {
          if (window.fbq) {
            // console.log("FB once?! Purchase")
            window.fbq('track', 'Purchase', { value: price, currency: 'EUR' });
          }
          if (window.ttq) {
            // console.log("TT once?! PlaceAnOrder")
            window.ttq.track('PlaceAnOrder', params);
          }
        };
        button.addEventListener('click', func1);
      }
    }
  }, [history.location.pathname]);

  return (
    <article className="mt-5">
      <Container>
        <Helmet>
          <title>{t('CHECKOUT.TITLE')}</title>
          <body className="checkout" />
        </Helmet>
        <h1 className="text-center mb-4">{t('CHECKOUT.TITLE')}</h1>
      </Container>
      <CheckoutWrapper />
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const cart = state.Cart.cart;

  const contents = cart?.items.flatMap((item) => {
    return Array(item.quantity).fill({
      content_id: item.variant.id,
      content_type: item.variant.article.id,
      content_name: item.variant.full_title,
    });
  });

  return [cart?.price_after_coupons, contents];
};

export default React.memo(Checkout);
